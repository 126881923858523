import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
    BackButtonComponent,
    BarChartComponent,
    DialogComponent,
    EntityAddressComponent,
    InfoComponent,
    LanguageSwitchComponent,
    LoadingIndicatorComponent,
    NotFoundComponent,
    SankeyChartComponent,
    SelectSearchComponent,
    TriplyCardComponent,
} from './components';
import { MaterialModule } from './modules/material/material.module';

@NgModule({
    declarations: [
        BarChartComponent,
        TriplyCardComponent,
        LoadingIndicatorComponent,
        SankeyChartComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        RouterModule,
        InfoComponent,
        SelectSearchComponent,
        LanguageSwitchComponent,
        BackButtonComponent,
        EntityAddressComponent,
        DialogComponent,
        NotFoundComponent,
    ],
    exports: [
        BarChartComponent,
        SelectSearchComponent,
        TriplyCardComponent,
        LanguageSwitchComponent,
        MaterialModule,
        SelectSearchComponent,
        BackButtonComponent,
        EntityAddressComponent,
        LoadingIndicatorComponent,
        SankeyChartComponent,
        DialogComponent,
        NotFoundComponent,
    ],
})
export class UiLibraryModule {}
