import { KeyValuePipe } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { MatButton } from '@angular/material/button';

import { Language, LanguageKeys, LanguageSwitcherService, languages } from '@shared/utils';

@Component({
    selector: 'language-switch',
    standalone: true,
    imports: [KeyValuePipe, MatButton],
    templateUrl: './language-switch.component.html',
    styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent {
    @Input() public backgroundColor?: string;
    @Input() public languages: { [key in LanguageKeys]?: Language } = languages;

    public showDropdown = false;
    public selectedLanguage: LanguageKeys;

    constructor(public languageSwitcherService: LanguageSwitcherService) {
        this.selectedLanguage = languageSwitcherService.getCurrentLanguage();
    }

    @HostListener('document:click', ['$event'])
    public onDocumentClick(event: MouseEvent): void {
        const element = document.getElementById('language-dropdown');
        if (
            (event.target as Node) !== document.getElementById('language-dropdown') &&
            element != null &&
            !element.contains(event.target as Node)
        ) {
            this.showDropdown = false;
        }
    }
}
