import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractApiWrapperService } from '@shared/utils';
import { AdminUserInfo, StrictHttpResponse, UserService } from '@upscore-admin/api';

@Injectable({
    providedIn: 'root',
})
export class UpscoreAdminApiWrapperService extends AbstractApiWrapperService<
    StrictHttpResponse<AdminUserInfo>,
    AdminUserInfo
> {
    constructor(private readonly userService: UserService) {
        super();
    }

    public loginPost(
        username: string,
        password: string,
    ): Observable<StrictHttpResponse<AdminUserInfo>> {
        const params: {
            body: {
                username: string;
                password: string;
            };
        } = {
            body: {
                username,
                password,
            },
        };

        return this.userService.adminLogin$Response(params);
    }

    public getUserInfo(): Observable<AdminUserInfo> {
        return this.userService.adminUserInfo();
    }
}
