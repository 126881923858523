import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { AuthService } from '@auth/data-access-auth';
import { ENVIRONMENT, Environment } from '@shared/utils';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
    public loginForm: UntypedFormGroup;
    public showPassword = false;
    public returnUrl: string | null = null;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly authService: AuthService,
        @Inject(ENVIRONMENT) public readonly environment: Environment,
    ) {
        this.returnUrl =
            this.route.snapshot.queryParamMap.get('redirect') ||
            this.route.snapshot.data['redirect'];
    }

    public ngOnInit(): void {
        this.initForm();
    }

    public login(): void {
        if (this.loginForm.invalid) {
            return;
        }

        this.authService
            .login(this.loginForm.value.username, this.loginForm.value.password)
            .pipe(take(1))
            .subscribe(() => {
                if (this.returnUrl) {
                    void this.router.navigateByUrl(this.returnUrl);
                }
            });
    }

    private initForm(): void {
        this.loginForm = this.formBuilder.group({
            username: [null, Validators.compose([Validators.required])],
            password: [null, Validators.compose([Validators.required])],
        });
    }
}
