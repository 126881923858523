import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatSlider, MatSliderChange, MatSliderThumb } from '@angular/material/slider';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { SliderColorClass } from '@ui-library/enums/slider-color-class.enum';

@Component({
    selector: 'upscore-slider[min][max][value]',
    templateUrl: './upscore-slider.component.html',
    standalone: true,
    imports: [DecimalPipe, MatSlider, MatSliderThumb],
    styleUrls: ['./upscore-slider.component.scss'],
})
export class UpscoreSliderComponent implements OnInit, OnDestroy {
    @Input() public min!: number;

    @Input() public max!: number;

    @Input() public step = 1;

    @Input() public value: number | null = null;

    @Input() public title?: string;

    @Input() public format?: string;

    @Input() public minMaxPosition: 'below' | 'beside' = 'below';

    @Input() public showValue = true;

    @Input() public matThemeColor: ThemePalette = 'primary';

    @Input() public colorClass?: SliderColorClass = SliderColorClass.WALKING; // can be used to overwrite material theme color

    @Input() public disabled = false;

    @Input() public debounced = false;

    @Output() public valueChanged: EventEmitter<number> = new EventEmitter<number>();

    private readonly debouncer: Subject<number> = new Subject<number>();

    private readonly unsubscribe$: Subject<void> = new Subject();

    public ngOnInit(): void {
        this.debouncer
            .pipe(debounceTime(this.debounced ? 300 : 0))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(value => this.valueChanged.emit(value));
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public onSliderValueChange(event: MatSliderChange): void {
        if (event.value != null) {
            this.value = event.value;
            this.debouncer.next(event.value);
        }
    }
}
