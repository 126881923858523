<div class="fixed bg-light flex items-center justify-center w-[600px] bg-white">
    <div class="bg-white p-8 rounded-lg shadow-lg w-full p-xxl">
        <div class="flex justify-between items-center">
            <h2 class="text-xl font-bold">{{ title }}</h2>
            <button
                class="text-gray-600 hover:text-gray-800"
                aria-label="Close"
                (click)="dialogClosed.emit()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <p class="mt-l">
            <ng-content></ng-content>
        </p>
    </div>
</div>
