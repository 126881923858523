import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(private readonly toastrService: ToastrService) {}

    /**
     * this shows snackbars in a queue and automatically closes them
     * @param errorFeedback
     * @param sticky
     * @param details
     * @param title
     * @param informationLink
     * @param showCloseBtn
     * @param rawMessage
     * @param enableReport
     * @param sentryEventId only usable with errorFeedback = true
     */
    public showInfoEvent(
        title: string | null,
        errorFeedback: boolean,
        sticky = false,
        details: string | null = null,
        informationLink: string | null = '',
        showCloseBtn = false,
        rawMessage: unknown = null,
        enableReport = true,
        sentryEventId: string | null = '',
        httpError: HttpErrorResponse | null = null,
        showDashboardButton = false,
        closeDialog?: () => void,
        custom?: {
            problematicEmployeeIds: string[];
        },
    ): void {
        // style is overwriten by .ngx-toastr in _mat-overwrite.scss

        if (errorFeedback) {
            this.toastrService.error(details ? details : '', title ? title : '', {
                // @ts-expect-error: custom attribute
                informationLink,
                rawMessage,
                sentryEventId,
                enableReport,
                httpError,
                closeButton: sticky,
                disableTimeOut: sticky,
                timeOut: sticky ? 0 : 10000,
                progressBar: !sticky,
                custom,
                enableHtml: true,
            });
        } else {
            this.toastrService.info(
                details != null ? details : title !== null ? title : '',
                details != null ? (title !== null ? title : '') : '',
                {
                    // @ts-expect-error: custom attribute
                    informationLink,
                    rawMessage,
                    closeButton: showCloseBtn,
                    disableTimeOut: sticky,
                    timeOut: sticky ? 0 : 10000,
                    progressBar: !sticky,
                    showDashboardButton: showDashboardButton,
                    closeDialog,
                    custom,
                    enableHtml: true,
                },
            );
        }
    }
}
