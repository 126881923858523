import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { FeatureFlag } from '../enums';

@Injectable({
    providedIn: 'root',
})
export abstract class AbstractUserDataService<T> {
    public _user$: BehaviorSubject<T | null> = new BehaviorSubject<T | null>(null);

    public get user$(): Observable<T> {
        return this._user$.pipe(filter(value => value != null)) as Observable<T>;
    }

    public get user(): T | null {
        return this._user$.value;
    }

    public updateUserInfo(userInfo: T): void {
        this._user$.next(userInfo);
    }

    public reset(): void {
        this._user$.next(null);
    }

    public abstract showFeature(feature: FeatureFlag): boolean;

    public abstract showFeature$(feature: FeatureFlag): Observable<boolean>;

    public abstract isUserType(userType: 'PREMIUM' | 'LIGHT' | 'VIEW_ONLY'): boolean;

    public abstract isUserType$(userType: 'PREMIUM' | 'LIGHT' | 'VIEW_ONLY'): Observable<boolean>;
}
