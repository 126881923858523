@if (!environment.disableTranslations) {
    <div class="h-[48px]" style="position: fixed; right: 2rem; top: 1rem">
        <language-switch backgroundColor="white"></language-switch>
    </div>
}

<div class="login-page">
    <div class="col-form">
        <img alt="Mobility Audit Logo" src="assets/images/upscore-brand/mobility-score-logo.svg" />

        <form [formGroup]="loginForm">
            <mat-form-field appearance="outline">
                <mat-label i18n="@@emailUsername">Email Or Username</mat-label>
                <input
                    autocomplete="username"
                    formControlName="username"
                    matInput
                    required
                    type="text" />
                @if (loginForm.controls['username'].value) {
                    <button
                        (click)="loginForm.controls['username'].setValue('')"
                        mat-icon-button
                        matSuffix
                        type="button">
                        <mat-icon>close</mat-icon>
                    </button>
                }
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label i18n="@@password">Password</mat-label>
                <input
                    [type]="showPassword ? 'text' : 'password'"
                    autocomplete="current-password"
                    formControlName="password"
                    matInput
                    required />
                <div matSuffix style="display: flex">
                    @if (loginForm.controls['password'].value) {
                        <button
                            (click)="showPassword = !showPassword"
                            mat-icon-button
                            type="button">
                            <mat-icon matSuffix>{{
                                showPassword ? 'visibility_off' : 'visibility'
                            }}</mat-icon>
                        </button>
                        <button
                            (click)="loginForm.controls['password'].setValue('')"
                            mat-icon-button
                            type="button">
                            <mat-icon>close</mat-icon>
                        </button>
                    }
                </div>
            </mat-form-field>

            <button
                (click)="login()"
                [disabled]="loginForm.invalid"
                class="login-button"
                color="primary"
                i18n="@@loginButtonText"
                mat-raised-button>
                Login
            </button>
        </form>
    </div>

    <div class="col-tool-preview">
        <img
            alt="Mobility Audit Tool Preview"
            src="assets/images/devices/imac-mobility-audit-en.png" />

        <h1 class="tool-headline" i18n="@@loginPageHeadline">Corporate Mobility Management</h1>
        <p class="tool-subline" i18n="@@loginPageDescription">
            Extensive analysis and tailored measures for improvement across 3 dimensions:
        </p>

        <div class="tool-dimensions">
            <mat-icon>check_circle</mat-icon>
            <p i18n="@@environment">Environment</p>
            <mat-icon>check_circle</mat-icon>
            <p i18n="@@cost">Cost</p>
            <mat-icon>check_circle</mat-icon>
            <p i18n="@@health">Health</p>
        </div>
    </div>
</div>
