import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    /**
     * get hex code from a css variable name
     * @param cssVarName
     */
    public getHexFromCSSVariable(cssVarName: string): string {
        return getComputedStyle(document.body).getPropertyValue(cssVarName);
    }

    public getModeColorCSSVariable(mode?: string | null): string {
        // see GetTripLegTypePipe for all pt modes
        switch (mode) {
            case 'WALK':
                return '--walk-color';
            case 'BIKE':
                return '--bike-color';
            case 'WAIT':
                return '#fff';
            case 'CAR_DRIVER_TRAFFIC':
                return '--danger-color';
            case 'CAR_DRIVER':
            case 'CAR':
                return '--car-driver-color';
            case 'CAR_PASSENGER':
                return '--car-passenger-color';
            case null:
            case undefined:
            case 'UNKNOWN':
            case '':
                return '--medium-grey-color';
            case 'PUBLIC_TRANSPORT':
            default:
                return '--public-transport-color';
        }
    }

    /* Get color based on trip mode */
    public getModeColor(mode?: string | null): string {
        return this.getHexFromCSSVariable(this.getModeColorCSSVariable(mode));
    }
}
