export interface HttpError {
    title?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: string | ((properties: any) => string);
    sticky?: boolean;
    link?: string;
    ignore?: boolean;
    showRawMessage?: boolean;
    custom?: (properties: any) => any;
    report?: boolean;
}
