import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, UrlSegment } from '@angular/router';

import { AuthService, ResolveUserDataService } from '@auth/data-access-auth';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly resolveUserDataService: ResolveUserDataService,
    ) {}

    public isLoggedIn(route: Route, segments: UrlSegment[]) {
        const isLoggedIn = this.authService.isLoggedIn();

        if (isLoggedIn) {
            return this.resolveUserDataService.resolve().then(() => {
                return true;
            });
        }

        void this.router.navigate(['auth', 'login'], {
            queryParams: { redirect: `/${segments.join('/')}` },
        });

        return false;
    }

    public isLoggedOut(): boolean {
        const isLoggedOut = !this.authService.isLoggedIn();

        if (!isLoggedOut) {
            void this.router.navigate([inject(ActivatedRouteSnapshot).data['redirect']]);
        }

        return isLoggedOut;
    }
}
