<div (click)="showDropdown = true" class="dropdown">
    <button
        [style.background-color]="backgroundColor"
        id="language-dropdown"
        class="!h-full"
        mat-stroked-button>
        <span class="fi fi-{{ languages[selectedLanguage]?.image }}"></span
        >{{ languages[selectedLanguage]?.name }}
    </button>
    <div [class.show]="this.showDropdown" class="dropdown-content">
        @for (languagePair of languages | keyvalue; track languagePair) {
            <a (click)="languageSwitcherService.changeLanguage($any(languagePair.key))"
                ><span class="fi fi-{{ languagePair.value?.image }}"></span
                >{{ languagePair.value?.name }}
            </a>
        }
    </div>
</div>
