import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'upscore-dialog',
    standalone: true,
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    imports: [MatIcon],
})
export class DialogComponent {
    @Output() public dialogClosed = new EventEmitter();
    @Input() public title: string;
}
