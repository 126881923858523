/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ImportResult } from '../models/import-result';

@Injectable({
  providedIn: 'root',
})
export class ImportExportService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation importCompanyLocation
   */
  static readonly ImportCompanyLocationPath = '/d/cl/import';

  /**
   * import company location from a zip file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importCompanyLocation()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importCompanyLocation$Response(params: {

    /**
     * username (&#x3D;email) of the user to whom the location will be imported
     */
    userName: string;
    context?: HttpContext
    body?: {

/**
 * a zip file to import
 */
'file': Blob;
}
  }
): Observable<StrictHttpResponse<ImportResult>> {

    const rb = new RequestBuilder(this.rootUrl, ImportExportService.ImportCompanyLocationPath, 'post');
    if (params) {
      rb.query('userName', params.userName, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImportResult>;
      })
    );
  }

  /**
   * import company location from a zip file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importCompanyLocation$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importCompanyLocation(params: {

    /**
     * username (&#x3D;email) of the user to whom the location will be imported
     */
    userName: string;
    context?: HttpContext
    body?: {

/**
 * a zip file to import
 */
'file': Blob;
}
  }
): Observable<ImportResult> {

    return this.importCompanyLocation$Response(params).pipe(
      map((r: StrictHttpResponse<ImportResult>) => r.body as ImportResult)
    );
  }

  /**
   * Path part for operation exportCompanyLocation
   */
  static readonly ExportCompanyLocationPath = '/d/cl/export/{companyLocationId}';

  /**
   * export company location as zip file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCompanyLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCompanyLocation$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ImportExportService.ExportCompanyLocationPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/zip',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * export company location as zip file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportCompanyLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCompanyLocation(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<any> {

    return this.exportCompanyLocation$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
